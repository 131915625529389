@font-face {
  font-family: 'Beausite';
  font-weight: normal;
  src: url('./fonts/BeausiteClassicWeb-Clear.woff');
}
@font-face {
  font-family: 'Beausite';
  font-weight: bold;
  src: url('./fonts/BeausiteClassicWeb-Semibold.woff');
}

:root {
  --text-color: #fff;
  --bg-color: #000;
}
html {
  font-size: 2vw;

  @media screen and (max-width: 1000px) {
    font-size: 2.5vw;
  }
  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
}
body {
  background: var(--bg-color);
  color: var(--text-color);
  margin: 0;
  font-family: 'Beausite', Arial, sans-serif;
  margin-bottom: 0;
  transition: 5s background-color, 5s color;
}
h1 {
  margin: 0;
  display: none;
}
a {
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.hero {
  min-height: 100vh;
  display: flex;
  align-items: flex-end;
  padding: 1rem;
  box-sizing: border-box;

  &__meta {
    width: 100%;
  }
  &__logo {
    position: fixed;
    top: 1rem;
    left: 1rem;
    width: calc(100% - 2rem);
    height: 15vmin;
    z-index: 1;
    pointer-events: none;

    svg {
      overflow: visible;

      path {
        fill: var(--bg-color);
        fill: transparent;
        stroke: var(--text-color);
        stroke-width: 0.8;
        transition: 5s stroke, 5s fill;

        @media screen and (max-width: 600px) {
          stroke-width: 1.5;
        }
      }
    }
    @media screen and (max-width: 600px) {
      height: 15vw;
    }
  }
  .row {
    display: flex;
    border-bottom: 1px solid var(--text-color);
    transition: 5s border-color;

    span {
      line-height: 1;
      margin: 0.25rem 0;
    }

    @media screen and (max-width: 600px) {
      flex-wrap: wrap;
    }
  }
}
h1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
}
em.divider {
  display: block;
  height: 0.95rem;
  flex-grow: 1;
  position: relative;
  // padding: 0 .5rem;
  margin: 0 1rem;
  transition: 0.2s opacity;

  &.hidden {
    // opacity: 0;
  }

  svg {
    position: absolute;
    top: 0.25rem;
    left: 0;
    overflow: visible;

    circle {
      stroke: var(--text-color);
      transition: 5s stroke;
    }
  }

  @media screen and (max-width: 600px) {
    min-width: 4rem;
  }
}

.projects {
  margin-top: 4rem;
  padding: 1rem;

  .row {
    display: flex;
    border-bottom: 1px solid var(--text-color);
    transition: 5s border-color;
  }
  .col {
    line-height: 1;
    margin: 0.3rem 0;

    @media screen and (max-width: 600px) {
      hyphens: auto;
    }

    &:nth-child(1) {
      width: 55%;
      padding-right: 0.5rem;
    }
    &:nth-child(2) {
      width: 35%;
      padding-right: 0.5rem;
    }
    &:nth-child(3) {
      width: 10%;
      text-align: right;
    }
  }
}
.projects__legend {
  font-size: .75rem;
  padding: 1rem;
  list-style-type: none;
  margin: 0;

  li {
    margin: 0;
    padding: 0;
  }
}

#footer {
  display: block;
  padding: 1rem;
  text-align: center;
  margin-top: 4rem;
  height: 2rem;
  font-family: monospace;
  // font-size: .75rem;
}